import styled from "styled-components";
import { Link } from "react-router-dom";
import { Typography, Card } from "@material-ui/core";

export const CompanyPhone = styled.a`
  display: flex;
  align-items: center;
  margin-top: 8px;
  justify-content: center;
  text-decoration: none;
  color: inherit;
`;

export const CompanyCardFlex = styled.div`
  display: flex;
  @media (max-width: 700px) {
    flex-wrap: wrap-reverse;
    justify-content: center;
    text-align: center;
  }
`;

export const CompanyLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid #eaeaea;
  margin: 30px 25px;
  width: 90%;
`;

export const CompanyDescription = styled(Typography)`
  margin-top: 12px;
  width: 85%;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const RatingText = styled.span`
  margin-left: 8px;
  color: ${(props) => (props.count ? "#ffb400" : "grey")};
`;

export const Ratings = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
  @media (max-width: 700px) {
    justify-content: center;
    text-align: center;
    margin: 8px 0;
  }
`;

export const CompanyAddress = styled(Typography)`
  font-style: italic;
  color: grey;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* text-align: left; */
`;

export const CompanyCategory = styled.div`
  text-transform: uppercase;
  font-size: 0.8em;
  margin-bottom: 8px;
`;

export const CompanyCardContainer = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
