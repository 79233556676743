import React, { useState, useRef, useEffect, createContext } from "react";
import {
  Container,
  Typography,
  Button,
  AppBar,
  Toolbar,
  Modal,
  Card,
  CardContent,
  TextField,
  Box,
  Avatar,
  Drawer,
  IconButton,
  List,
  ListItem,
} from "@material-ui/core";
import { useHistory, Route, Switch } from "react-router-dom";

import CompanyDetail from "./components/CompanyDetail";
import Companies from "./components/Companies";

import "./App.css";
import {
  signInWithEmailAndPassword,
  signInWithGoogle,
  signInWithFacebook,
} from "./firebase";
import firebase from "firebase";
import NewCompany from "./components/NewCompany";
import Loading from "./components/Loading";
import useMedia from "./components/useMedia";
import MenuIcon from "@material-ui/icons/Menu";
import { ReactComponent as BackIcon } from "./icons/arrow-left.svg";
import { red } from "@material-ui/core/colors";
import styled from "styled-components";

const FooterContainer = styled(Container)`
  && {
    display: flex;
    justify-content: space-between;
    @media (max-width: 700px) {
      flex-direction: column;
    }
  }
`;

export const AppContext = createContext();

function App() {
  const [open, setOpen] = useState(false);
  const [auth, setAuth] = useState(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    let unsub = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then(({ claims }) => {
          setAuth(user);
          setIsSuperAdmin(claims.super_admin || false);
          setOpen(false);
          return;
        });
      }
      setAuth(false);
    });
    return unsub;
  }, []);

  if (auth === null) return <Loading />;

  return (
    <AppContext.Provider
      value={{
        openLoginModal: () => setOpen(true),
        closeLoginModal: () => setOpen(false),
        auth,
        isSuperAdmin,
      }}
    >
      <Modal open={open} onClose={() => setOpen(false)}>
        <LoginForm setOpen={setOpen} />
      </Modal>
      <KKToolbar
        auth={auth}
        modalState={open}
        openLoginModal={() => setOpen(true)}
      />
      <div>
        <Switch>
          <Route path="/apply">
            <NewCompany />
          </Route>
          <Route path="/:key">
            <CompanyDetail />
          </Route>
          <Route path="/" exact>
            <Companies />
          </Route>
        </Switch>
      </div>
      <KKFooter />
    </AppContext.Provider>
  );
}

function KKToolbar({ auth, openLoginModal, modalState }) {
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (modalState) setOpenDrawer(false);
  }, [modalState]);

  const small = useMedia("(max-width: 700px)");

  return (
    <>
      <AppBar
        position="static"
        boxShadow={3}
        style={{
          zIndex: 2,
          position: "relative",
        }}
      >
        <Toolbar>
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {small && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpenDrawer(true)}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography
              variant="h6"
              style={{ flexGrow: 1, cursor: "pointer" }}
              onClick={() => history.push("/")}
            >
              Nepalese Business Directory
            </Typography>
            {small ? null : auth ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: 4,
                    marginRight: 8,
                  }}
                >
                  <Avatar
                    style={{ height: 25, width: 25, marginRight: 8 }}
                    alt={firebase.auth().currentUser.displayName}
                    src={firebase.auth().currentUser.photoURL}
                  />
                  <span>{firebase.auth().currentUser.displayName}</span>
                </div>
                <Button
                  color="inherit"
                  onClick={() => firebase.auth().signOut()}
                >
                  Logout
                </Button>
              </>
            ) : (
              <Button color="inherit" onClick={openLoginModal}>
                Login
              </Button>
            )}
          </Container>
        </Toolbar>
      </AppBar>
      {small && (
        <Drawer
          anchor="left"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <List>
            {auth ? (
              <>
                <ListItem>
                  <Avatar
                    style={{ height: 25, width: 25, marginRight: 8 }}
                    alt={firebase.auth().currentUser.displayName}
                    src={firebase.auth().currentUser.photoURL}
                  />
                  <span>{firebase.auth().currentUser.displayName}</span>
                </ListItem>
                <ListItem>
                  <Button
                    color="inherit"
                    onClick={() => firebase.auth().signOut()}
                  >
                    Logout
                  </Button>
                </ListItem>
              </>
            ) : (
              <ListItem>
                <Button color="inherit" onClick={openLoginModal}>
                  Login
                </Button>
              </ListItem>
            )}
            <ListItem>
              <Button color="inherit" onClick={() => history.push("/apply")}>
                Add Your Company
              </Button>
            </ListItem>
          </List>
        </Drawer>
      )}
    </>
  );
}

function KKFooter() {
  return (
    <footer style={{ background: red["500"], color: "white", padding: 20 }}>
      <FooterContainer>
        <div>
          <span className="copyright">
            Copyright © 2020 Kaam Kotha Group Pty Ltd
          </span>
        </div>
        <div>
          <span className="privacy" style={{ marginRight: 16 }}>
            <a href="/privacy.html" target="_blank" style={{ color: "white" }}>
              Privacy Policy
            </a>
          </span>
          <span className="tnc">
            <a href="/toc.html" target="_blank" style={{ color: "white" }}>
              Terms of Service
            </a>
          </span>
        </div>
      </FooterContainer>
    </footer>
  );
}

function LoginForm({ setOpen }) {
  const [loading, setLoading] = useState(false);
  const small = useMedia("(max-width: 700px)");

  const emailRef = useRef();
  const passwordRef = useRef();

  function signIn(e) {
    e.preventDefault();
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(
        emailRef.current.value,
        passwordRef.current.value
      )
      .then((x) => {
        setOpen(false);
      })
      .catch((e) => {
        alert(e.message);
      })
      .finally(() => setLoading(false));
  }

  return (
    <Card
      style={{
        width: "100%",
        maxWidth: 600,
        outline: "none",
        margin: "auto",
        textAlign: "center",
        height: small ? "100%" : "auto",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <IconButton
            onClick={() => setOpen(false)}
            style={{ marginLeft: -20 }}
          >
            <BackIcon style={{ fill: "white" }} />
          </IconButton>
          <Typography variant="h6">Login</Typography>
        </Toolbar>
      </AppBar>
      <CardContent style={{ padding: "40px 32px" }}>
        <Typography variant="h5" color="primary">
          Login with KaamKotha
        </Typography>
        <div>
          <form onSubmit={(e) => signIn(e)}>
            <TextField
              label="Email"
              style={{ width: "100%", marginBottom: 8 }}
              inputRef={emailRef}
            />
            <TextField
              label="Password"
              type="password"
              style={{ width: "100%", marginBottom: 8 }}
              inputRef={passwordRef}
            />
            {loading ? (
              <span>Signing in..</span>
            ) : (
              <Button
                variant="contained"
                style={{ marginTop: 16 }}
                color="primary"
                size="small"
                type="submit"

                // onClick={signIn}
              >
                Sign In
              </Button>
            )}
            <br />
            <br />
            <Button variant="outlined" onClick={() => signInWithGoogle()}>
              Sign In With Google
            </Button>
            <br />
            <br />
            <Button variant="outlined" onClick={() => signInWithFacebook()}>
              Sign In With Facebook
            </Button>
          </form>
        </div>
      </CardContent>
    </Card>
  );
}

export default App;
