import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import { BrowserRouter as Router } from "react-router-dom";
import { green } from "@material-ui/core/colors";
import { SnackbarProvider } from "notistack";

const theme = createMuiTheme({
  palette: {
    primary: red,
    secondary: green,
    purple: "#8e24aa",
  },
  shape: {
    borderRadius: 0,
  },
});

function Root() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Router>
          <SnackbarProvider
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <App />
          </SnackbarProvider>
        </Router>
      </ThemeProvider>
    </React.StrictMode>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
