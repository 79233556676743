import React from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Loading() {
  return (
    <LoadingContainer>
      <CircularProgress color="primary" size={80} value={70} />
    </LoadingContainer>
  );
}
