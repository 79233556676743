import firebase from "firebase";

firebase.initializeApp({
  apiKey: "AIzaSyCt-oWU7QZWJ8DCmmE16UVDTKZpl7iwKC0",
  authDomain: "fir-kaamkotha.firebaseapp.com",
  databaseURL: "https://fir-kaamkotha.firebaseio.com",
  projectId: "firebase-kaamkotha",
  storageBucket: "firebase-kaamkotha.appspot.com",
  messagingSenderId: "42023066562",
  appId: "1:42023066562:web:c6dd506a7cf95f35c0d45b",
  measurementId: "G-BD8T3LZC1S",
});
const _firestore = firebase.firestore();

// firebase.auth().signInWithEmailAndPassword("admin@kaamkotha.com", "Admin@123$");
// firebase.auth().signOut();

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();

export async function signInWithGoogle() {
  return await firebase.auth().signInWithPopup(googleProvider);
}
export async function signInWithFacebook() {
  return await firebase.auth().signInWithPopup(facebookProvider);
}

export async function getCompany(companyKey) {
  return (
    await _firestore.collection("companies").doc(companyKey).get()
  ).data();
}
export async function getReviews(companyKey, limit = 50, firstPost, lastPost) {
  let query = _firestore
    .collection("companies")
    .doc(companyKey)
    .collection("reviews");
  query = query.orderBy("createdAt", "desc");
  if (lastPost)
    query = query
      .orderBy("message")
      .startAfter(lastPost.createdAt, lastPost.message);
  else if (firstPost)
    query = query
      .orderBy("message")
      .endBefore(firstPost.createdAt, firstPost.message);
  const data = await query.limit(limit).get();
  return data.docs.map((d) => d.data());
}

export async function getUserReview(companyKey) {
  if (!firebase.auth().currentUser) return;
  let review = (
    await _firestore
      .collection("companies")
      .doc(companyKey)
      .collection("reviews")
      .doc(firebase.auth().currentUser.uid)
      .get()
  ).data();
  return review;
}

/**
 *
 * @param {String} companyKey
 * @param {Map} review  It must contain stars(1-5), message(optional)
 */
export async function addReview(companyKey, review) {
  const user = firebase.auth().currentUser;
  if (user == null) throw Error("User must be logged in");
  review.uid = user.uid;
  review.companyKey = companyKey;
  review.imageUrl = user.photoURL;
  review.updatedAt = Date.now();
  review.displayName = user.displayName;
  review.createdAt = review.createdAt || Date.now();

  await _firestore
    .collection("companies")
    .doc(companyKey)
    .collection("reviews")
    .doc(user.uid)
    .set(review, { merge: true });
  return review;
}

export async function deleteReview(companyKey, reviewKey) {
  const uid = firebase.auth().currentUser.uid;
  if (uid == null) throw Error("User must be logged in");
  await _firestore
    .collection("companies")
    .doc(companyKey)
    .collection("reviews")
    .doc(reviewKey)
    .delete();
}

export async function signInWithEmailAndPassword(email, password) {
  return firebase.auth().signInWithEmailAndPassword(email, password);
}

export async function getCategories() {
  return (
    await firebase.firestore().collection("categories").get()
  ).docs.map((i) => i.data());
}

export async function sendApplication(data) {
  let dataRef = firebase.firestore().collection("applications").doc();
  data.key = dataRef.id;
  return await dataRef.set(data);
}
