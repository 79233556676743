import React, { useState, useRef, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import algoliasearch from "algoliasearch/lite";

import {
  Container,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Button,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import CompanyCard from "./CompanyCard";
import mobile from "../images/mobile.png";
import styled from "styled-components";
import Loading from "./Loading";
import firebase from "firebase";
import useMedia from "./useMedia";
import { AppContext } from "../App";

const HeroTitle = styled.span`
  color: white;
  font-size: 50px;
  margin: 20px 0;
  @media (max-width: 900px) {
    font-size: 35px;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 5px;
  }
`;

const HeroSubtitle = styled.span`
  color: white;
  font-size: 30px;
  margin: 20px 0;
  @media (max-width: 900px) {
    font-size: 25px;
    margin-bottom: 5px;
    margin-top: 10px;
    padding: 10px;
  }
  margin-top: -50px;
`;

const HeroWrapper = styled.div`
  grid-row: 1;
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: serif;
`;

const BannerInfo = styled.div`
  background-color: #f44336;
  float: left;
  color: white;
  grid-row: 1;
  grid-column: 1/2;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  @media (max-width: 1350px) {
    display: none;
  }
`;

const HeroFieldsContainer = styled(Container)`
  && {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* margin: 10px 0; */
    /* height: 100%; */
    .searchInput,
    .searchSelect {
      margin-right: 8px;
    }
    @media (max-width: 950px) {
      flex-direction: column;
      .searchInput,
      .searchSelect {
        margin-right: 0;
      }
      .searchButton {
        width: 250px;
      }
    }
  }
`;

const Grid = styled.div.attrs({
  // id: "results",
})`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 24px;
  margin: 24px 0;
  @media (max-width: 700px) {
    grid-template-columns: unset;
  }
`;

const Title = styled(Typography)`
  text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1),
    1px 1px 1px rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  margin-bottom: 8px;
`;

const Hero = styled.div`
  min-height: 480px;
  width: 100%;
  background-color: #1d5caa;
  display: grid;
  grid-template-columns: 20% 80%;
  position: relative;
  &:after {
    position: absolute;
    content: "";
    right: 0;
    bottom: 0;
    background: url(${mobile});
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 300px;
    background-position-y: bottom;
    @media (max-width: 800px) {
      display: none;
    }
  }
  @media (max-width: 800px) {
    padding-bottom: 20px;
  }
`;

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

const client = algoliasearch("3OA7U3PTYB", "a911f9522ad422140da1863d0847e95f");
const index = client.initIndex("companies");

export default function Companies({}) {
  const small = useMedia("(max-width: 700px)");

  const [companies, setCompanies] = useState(null);
  const [categories, setCategories] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("ALL");
  const [selectedState, setSelectedState] = useState("ALL");
  const [paginationMeta, setPaginationMeta] = useState({});
  const history = useHistory();

  const { auth, openLoginModal } = useContext(AppContext);

  const [searchField, setSearchField] = useState("");

  function filter({ term = searchField, page, init }) {
    return index
      .search(term, {
        facets: ["category", "states"],
        hitsPerPage: 20,
        page: page,
        facetFilters: [
          `category:${selectedCategory === "ALL" ? "" : selectedCategory}`,
          `states:${selectedState === "ALL" ? "" : selectedState}`,
        ],
      })
      .then((res) => {
        const nonArchivedRes = res.hits.filter((h) => !h.archive);
        if (term === "") {
          setCompanies(shuffle(nonArchivedRes));
        } else {
          setCompanies(nonArchivedRes);
        }
        setPaginationMeta({
          current_page: res.page + 1,
          total_pages: res.nbPages,
        });
        if (!init && small) {
          document.getElementById("results").scrollIntoView({
            behavior: "smooth",
            inline: "center",
          });
        }

        return res;
      });
  }

  useEffect(() => {
    filter({ init: true }).then((res) => {
      setCategories(res.facets.category);
      setStates(res.facets.states);
    });
  }, []);

  function handleChange(e) {
    const search = e.target.value;
    setSearchField(search);
  }

  function applyForListing() {
    history.push("/apply");
  }

  if (!companies || !categories) return <Loading />;
  return (
    <>
      <Hero>
        <BannerInfo>
          According to a Google study, 80% of consumers regularly query search
          engines for local businesses, with half of them visiting a local
          business within the day of the search. <br /> <br /> Our exclusive
          Business Directory service can now have your business listed and
          updated live through our network.
        </BannerInfo>
        <HeroWrapper>
          <HeroSubtitle>
            "Connecting Nepalese Community In Australia"
          </HeroSubtitle>
          <HeroTitle>Nepalese Business Directory</HeroTitle>
          <span
            style={{
              position: "absolute",
              bottom: 2,
              right: 2,
              color: "white",
              zIndex: 3,
              fontFamily: "sans-serif",
            }}
          >
            Powered By Kaamkotha
          </span>
          <HeroFieldsContainer>
            <TextField
              style={{
                backgroundColor: "white",
                marginTop: 8,
              }}
              id="standard-basic"
              className="searchInput"
              placeholder="Search for companies"
              variant="outlined"
              onChange={handleChange}
            />
            <FormControl
              className="searchSelect categories"
              variant="outlined"
              style={{ background: "white", marginTop: 8 }}
            >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                // value={"ALL"}
              >
                <MenuItem value="ALL">All Categories</MenuItem>
                {Object.keys(categories)
                  .sort()
                  .map((i) => (
                    <MenuItem
                      key={i}
                      value={i}
                    >{`${i} [${categories[i]}] `}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              className="searchSelect states"
              variant="outlined"
              style={{ background: "white", marginTop: 8 }}
            >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
              >
                <MenuItem value="ALL">All States</MenuItem>
                {Object.keys(states).map((i) => (
                  <MenuItem value={i}>{`${i} [${states[i]}] `}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ width: "100%" }} />
            <Button
              style={{ marginTop: 8 }}
              className="searchButton"
              variant="contained"
              color="primary"
              onClick={() => filter({})}
            >
              Search
            </Button>
          </HeroFieldsContainer>
        </HeroWrapper>
      </Hero>
      <Container style={{ marginTop: 24 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{
            maxWidth: 900,
            margin: "auto",
            display: "block",
            width: "100%",
          }}
          onClick={applyForListing}
        >
          Add Your company for listing
        </Button>
        <div style={{ display: "flex", margin: "auto" }} id="results">
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <Grid>
              {companies &&
                companies.map((company) => {
                  return <CompanyCard key={company.id} company={company} />;
                })}
            </Grid>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "24px 0",
              }}
            >
              <Pagination
                color="primary"
                count={paginationMeta.total_pages || 0}
                page={paginationMeta.current_page || 1}
                onChange={(e, v) => filter({ term: searchField, page: v - 1 })}
              />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
