import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Card,
  CardContent,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Box,
  Modal,
  TextField,
  Breadcrumbs,
  Link,
  Collapse,
  Avatar,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import moment from "moment";
import { useRouteMatch, Link as RouterLink } from "react-router-dom";

import { ReactComponent as BackIcon } from "../icons/arrow-left.svg";
import { red } from "@material-ui/core/colors";
// import Rating from "react-rating";
import Rating from "@material-ui/lab/Rating";
import EditIcon from "@material-ui/icons/Edit";

import {
  getReviews,
  getCompany,
  addReview,
  getUserReview,
  deleteReview,
} from "../firebase";
import {
  CompanyCardContainer,
  CompanyCategory,
  CompanyLink,
  CompanyAddress,
  Ratings,
  RatingText,
  CompanyPhone,
  Divider,
  CompanyCardFlex,
} from "./styles";
import LanguageIcon from "@material-ui/icons/Language";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";
import PhoneAndroidOutlinedIcon from "@material-ui/icons/PhoneAndroidOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import SMSIcon from "@material-ui/icons/SmsOutlined";
import styled from "styled-components";
import firebase from "firebase";
import Loading from "./Loading";
import { useSnackbar } from "notistack";
import { AppContext } from "../App";
import Icon from "../Icon";
import useMedia from "./useMedia";

const ReviewDate = styled.span`
  font-size: 12px;
  color: #aaa;
  margin-left: 4px;
`;

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

function Spacer({ space }) {
  return <div style={{ height: space }} />;
}

function getRatingText(stars) {
  if (!stars) return "";
  let x = [1, 2, 3, 4, 5]
    .map((_, i) => {
      if (i < stars) return "★";
      return "☆";
    })
    .join("");
  return x + " " + stars + "/5 stars";
}

function getShareLink(company) {
  const text = getRatingText(company.averageStars);
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: "nepalesebusinessdirectory.page.link",
        link: window.location.href,
        socialMetaTagInfo: {
          socialTitle: company.name,
          socialDescription: text + ". " + company.description,
          socialImageLink: company.imageURL,
        },
      },
      suffix: {
        option: "SHORT",
      },
    }),
  }).then((res) => res.json());
}

const url =
  "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCt-oWU7QZWJ8DCmmE16UVDTKZpl7iwKC0";

export default function CompanyDetail() {
  const { params } = useRouteMatch();
  const key = params.key;
  const [company, setCompany] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [review, setReview] = useState({});
  const [collapsed, setCollapsed] = useState(true);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { openLoginModal } = useContext(AppContext);
  const [shareLink, setShareLink] = useState(null);

  const small = useMedia("(max-width: 700px)");

  function toggleCollapse() {
    setCollapsed((c) => !c);
  }

  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    getCompany(key).then((company) => {
      setCompany(company);
    });
    getUserReview(key).then((x) => {
      if (x) setReview(x);
    });
    getReviews(key, 20, null, null).then((reviews) => {
      setReviews(reviews);
    });
  }, [key]);

  useEffect(() => {
    if (company) {
      setCompany((company) => {
        let data = reviews;
        if (review.uid)
          data = [review, ...reviews.filter((i) => i.uid !== review.uid)];
        company.averageStars =
          data.reduce((prev, curr) => prev + curr.stars, 0) / data.length;
        company.ratingsCount = data.length;
        getShareLink(company).then((x) => {
          setShareLink(x.shortLink);
        });
        return company;
      });
    }
  }, [review, company, reviews, shareLink]);

  let days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  if (!company || loading) return <Loading />;

  let hours = company.trading_hours.reduce((prev, curr) => {
    prev[curr.day] = curr;
    return prev;
  }, {});

  async function sendReview(review) {
    let res = await addReview(key, review);
    setOpen(false);
    setReview(res);
    getReviews(key, 20, null, null).then(setReviews);
  }

  async function deleteReviewAction(reviewKey) {
    if (window.confirm("Are You sure you want to delete the review?")) {
      setLoading(true);
      await deleteReview(key, reviewKey);
      getReviews(key, 20, null, null).then((reviews) => {
        setReviews(reviews);
        setReview({});
        setLoading(false);
      });
    }
    return;
  }

  function handleWriteReviewClick() {
    if (!firebase.auth().currentUser) {
      enqueueSnackbar("Oops! You need to login before you can write a review", {
        variant: "error",
      });
      openLoginModal();
      return;
    }
    setOpen(true);
  }

  function renderReviews() {
    if (!reviews)
      return (
        <Box>
          <Typography>No Reviews</Typography>
        </Box>
      );

    let data = reviews;

    if (review.uid) data = reviews.filter((i) => i.uid !== review.uid);

    return data.map((review) => (
      <div key={review.uid}>
        <Review
          company={company}
          review={review}
          deleteReviewAction={deleteReviewAction}
          editAction={() => setOpen(true)}
        />
        <br />
      </div>
    ));
  }

  return (
    <>
      <Container style={{ marginTop: 24, maxWidth: 800 }}>
        <Breadcrumbs
          separator="›"
          aria-label="breadcrumb"
          style={{ marginBottom: 12 }}
        >
          <LinkRouter color="inherit" to="/">
            Home
          </LinkRouter>

          <Typography color="textPrimary">{company.name}</Typography>
        </Breadcrumbs>
        <CompanyCardContainer style={{ marginBottom: 24 }}>
          <CardContent style={{ flexGrow: 1, padding: "18px 32px" }}>
            <CompanyCategory>{company.category}</CompanyCategory>
            <CompanyCardFlex style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <CompanyLink to={"/" + company.id}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    {company.name}
                  </Typography>
                </CompanyLink>
                <CompanyAddress>
                  <LocationOnOutlinedIcon fontSize="small" /> {company.address}
                </CompanyAddress>
                <Ratings>
                  <Rating
                    name="hover-feedback"
                    value={company.averageStars || 0}
                    precision={0.5}
                    readOnly
                    size="small"
                  />
                  <RatingText count={company.ratingsCount}>
                    {company.ratingsCount
                      ? `${company.ratingsCount} reviews`
                      : "No reviews yet"}
                  </RatingText>
                </Ratings>
                <Spacer space={8} />
                <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {company.phone ? (
                    <a
                      style={{ textDecoration: "none" }}
                      href={`tel:${company.phone}`}
                      target="__blank"
                    >
                      <Button
                        size="small"
                        color="primary"
                        variant="outlined"
                        style={{
                          width: 150,
                          margin: 4,
                          borderRadius: 4,
                          justifyContent: "flex-start",
                        }}
                        startIcon={
                          <PhoneAndroidOutlinedIcon
                            style={{ height: 24, width: 24 }}
                          />
                        }
                      >
                        {company.phone}
                      </Button>
                    </a>
                  ) : null}
                  <br />
                  <a
                    style={{ textDecoration: "none" }}
                    href={company.website}
                    target="__blank"
                  >
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      style={{
                        width: 150,
                        margin: 4,
                        borderRadius: 4,
                        justifyContent: "flex-start",
                      }}
                      startIcon={
                        <LanguageIcon style={{ height: 24, width: 24 }} />
                      }
                    >
                      Visit website
                    </Button>
                  </a>
                  <br />
                  <a
                    style={{ textDecoration: "none" }}
                    href={"mailto:" + company.email}
                    target="__blank"
                  >
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      style={{
                        width: 150,
                        margin: 4,
                        borderRadius: 4,
                        justifyContent: "flex-start",
                      }}
                      startIcon={
                        <MailOutlineOutlinedIcon
                          style={{ height: 24, width: 24 }}
                        />
                      }
                    >
                      Send Email
                    </Button>
                  </a>
                  <br />
                  <a
                    style={{ textDecoration: "none" }}
                    href={`https://maps.google.com/?q=${company.name},${company.address}`}
                    target="__blank"
                  >
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      style={{
                        width: 150,
                        margin: 4,
                        borderRadius: 4,
                        justifyContent: "flex-start",
                      }}
                      startIcon={
                        <MapOutlinedIcon style={{ height: 24, width: 24 }} />
                      }
                    >
                      Show on Map
                    </Button>
                  </a>
                </div>
              </div>
              <div>
                <img
                  src={company.imageURL}
                  style={{ width: 200, borderRadius: 8 }}
                  alt=""
                />
              </div>
            </CompanyCardFlex>

            <Spacer space={24} />
            {company.description.length > 900 ? (
              <>
                <Collapse
                  collapsedHeight={"8.4em"}
                  in={!collapsed}
                  style={{ position: "relative", lineHeight: "1.4em" }}
                >
                  <span style={{ whiteSpace: "break-spaces" }}>
                    {company.description}
                  </span>
                  <div
                    style={{
                      display: collapsed ? "inline-block" : "none",
                      width: "100%",
                      height: 40,
                      bottom: 0,
                      left: 0,
                      position: "absolute",
                      background:
                        "linear-gradient(to bottom,rgba(255,255,255,0),white)",
                    }}
                  ></div>
                </Collapse>
                <div style={{ textAlign: "center", marginTop: 20 }}>
                  <Button
                    color="primary"
                    style={{ fontWeight: 600 }}
                    onClick={toggleCollapse}
                  >
                    {collapsed ? "Read More" : "Collapse"}
                  </Button>
                </div>
              </>
            ) : (
              <span style={{ whiteSpace: "break-spaces" }}>
                {company.description}
              </span>
            )}

            <Divider />

            <div>
              <CompanyCategory style={{ fontSize: "1em" }}>
                Trading Hours
              </CompanyCategory>
              {days.map((day) => (
                <div key={day}>
                  <strong style={{ width: "30%", display: "inline-block" }}>
                    {day}
                  </strong>
                  <span style={{ width: "70%", display: "inline-block" }}>
                    {hours[day]
                      ? moment(hours[day].opening_time).format("hh:mm A") +
                        " - " +
                        moment(hours[day].closing_time).format("hh:mm A")
                      : "Closed"}
                  </span>
                </div>
              ))}
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <CompanyCategory style={{ fontSize: "1em", width: "100%" }}>
                Share Via
              </CompanyCategory>
              {shareLink && (
                <iframe
                  title={company.name + " Share"}
                  src={`https://www.facebook.com/plugins/share_button.php?href=${encodeURIComponent(
                    shareLink
                  )}&layout=button&size=small&appId=1496836183876381&width=67&height=20`}
                  width="67"
                  height="20"
                  style={{ border: "none", overflow: "hidden", margin: "4px" }}
                  scrolling="no"
                  frameBorder="0"
                  allowTransparency="true"
                  allow="encrypted-media"
                ></iframe>
              )}
              {small && (
                <>
                  <a
                    style={{ textDecoration: "none" }}
                    href={`viber://forward?text=Check out ${company.name} on: ${shareLink}`}
                    target="__blank"
                  >
                    <Button
                      size="small"
                      color="purple"
                      variant="contained"
                      style={{
                        height: 20,
                        margin: 4,
                        boxShadow: "none",
                        textTransform: "capitalize",
                        color: "white",
                        background: "#8e24aa",
                        borderRadius: 4,
                        justifyContent: "flex-start",
                        fontSize: 11,
                      }}
                      startIcon={
                        <Icon
                          category={{
                            icon_pack: "mdi",
                            icon_name: "viber",
                          }}
                          styles={{
                            height: 16,
                            width: 16,
                            fill: "white",
                          }}
                        />
                      }
                    >
                      Viber
                    </Button>
                  </a>
                  <a
                    style={{ textDecoration: "none" }}
                    href={`sms:?body=Check out ${company.name} on: ${shareLink}`}
                    target="__blank"
                  >
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      style={{
                        height: 20,
                        margin: 4,
                        boxShadow: "none",
                        textTransform: "capitalize",
                        borderRadius: 4,
                        justifyContent: "flex-start",
                        fontSize: 11,
                      }}
                      startIcon={<SMSIcon style={{ height: 16, width: 16 }} />}
                    >
                      SMS
                    </Button>
                  </a>
                </>
              )}
            </div>

            <Divider />

            <div>
              {review.uid ? (
                <div>
                  <Review
                    review={review}
                    editAction={() => setOpen(true)}
                    deleteReviewAction={deleteReviewAction}
                    company={company}
                  />
                  <br />
                </div>
              ) : null}
              <CompanyCategory style={{ fontSize: "1em" }}>
                Reviews
              </CompanyCategory>
              <div
                role="button"
                onClick={handleWriteReviewClick}
                style={{ cursor: "pointer" }}
              >
                {Object.keys(review).length === 0 ? (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleWriteReviewClick}
                  >
                    <EditIcon
                      fontSize="small"
                      color="disabled"
                      style={{ marginRight: 4 }}
                    />
                    Write Review
                  </Button>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    padding: "20px",
                    boxSizing: "border-box",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "64px",
                      fontWeight: 100,
                      lineHeight: "64px",
                    }}
                  >
                    {Number(company.averageStars || 0).toFixed(1)}
                  </span>
                  <Rating
                    name="hover-feedback"
                    value={company.averageStars || 0}
                    precision={0.5}
                    readOnly
                  />
                  <span>{company.ratingsCount || 0} reviews</span>
                </div>
              </div>
              {renderReviews()}
            </div>
          </CardContent>
        </CompanyCardContainer>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReviewForm
          review={review}
          onSubmit={sendReview}
          handleClose={handleClose}
          company={company}
        />
      </Modal>
    </>
  );
}

function DeleteConfirmation({ handleCancel, handleOk, open }) {
  return (
    <Dialog open={open}>
      <DialogTitle id="confirmation-dialog-title">
        Are you sure you want to delete the review?
      </DialogTitle>

      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ReviewForm({
  handleClose,
  review = {},
  companyKey,
  onSubmit,
  company,
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [form, setForm] = useState(review);
  const [loading, setLoading] = useState(false);

  function submit() {
    if (!form.stars) {
      enqueueSnackbar("You need to give star.", {
        variant: "error",
      });
      return;
    }
    setLoading(true);

    onSubmit(form);
  }

  return (
    <Card style={{ width: "100%", maxWidth: "800px" }}>
      <Toolbar style={{ background: red["500"] }}>
        <IconButton onClick={handleClose}>
          <BackIcon style={{ fill: "white" }} />
          <span style={{ color: "white", marginLeft: 8 }}>
            Review for {company.name}
          </span>
        </IconButton>
      </Toolbar>
      <CardContent style={{ paddingLeft: 40, paddingRight: 40 }}>
        Reviews are public and editable.
        <br />
        <br />
        <Rating
          value={form.stars}
          precision={1}
          step
          size="large"
          onChange={(e, v) => {
            console.log({ v });
            setForm({
              ...form,
              stars: v,
            });
          }}
        />
        <br />
        <br />
        <TextField
          variant="outlined"
          label="Review"
          multiline
          fullWidth
          rows={8}
          value={form.message}
          onChange={(e) => {
            setForm({
              ...form,
              message: e.target.value,
            });
          }}
        ></TextField>
        <br />
        <br />
        <Button
          disabled={loading}
          onClick={submit}
          variant="contained"
          color="primary"
        >
          {loading ? (
            <span>
              <CircularProgress
                color="white"
                size="1em"
                style={{ marginRight: 8 }}
              />{" "}
            </span>
          ) : null}
          Submit
        </Button>
        <Button style={{ marginLeft: 8 }} onClick={handleClose}>
          Close
        </Button>
      </CardContent>
    </Card>
  );
}

function Review({ review, deleteReviewAction, editAction, company }) {
  const { isSuperAdmin } = useContext(AppContext);

  return (
    <Box display="flex" alignItems="flex-start">
      <Avatar
        alt={review.displayName}
        src={review.imageUrl}
        style={{ marginTop: 4 }}
      ></Avatar>
      <Box display="flex" flexDirection="column" style={{ marginLeft: 12 }}>
        <Typography style={{ fontWeight: "bold" }}>
          {review.displayName}
        </Typography>
        <Box display="flex" alignItems="center">
          <Rating
            name="hover-feedback"
            value={review.stars}
            style={{ fontSize: "0.9rem" }}
            precision={0.5}
            readOnly
            size="small"
          />
          <ReviewDate>
            {moment(review.createdAt).format("MMM D, YYYY")}
          </ReviewDate>
        </Box>
        <span style={{ fontWeight: 100, marginTop: 8 }}>{review.message}</span>

        {firebase.auth().currentUser &&
        review.uid === firebase.auth().currentUser.uid ? (
          <div>
            <Button onClick={editAction}>Edit</Button>
            <Button onClick={() => deleteReviewAction(review.uid)}>
              Delete
            </Button>
          </div>
        ) : null}
        {review.message && review.reply ? (
          <>
            <Box
              style={{ marginTop: 5 }}
              display="flex"
              alignItems="flex-start"
            >
              <Avatar
                alt={company.name}
                src={company.imageURL}
                style={{ marginTop: 4 }}
              ></Avatar>
              <Box
                display="flex"
                flexDirection="column"
                style={{ marginLeft: 12 }}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {company.name}
                </Typography>
                <ReviewDate>replied:</ReviewDate>

                <span
                  style={{
                    fontWeight: 100,
                    marginTop: 2,
                  }}
                >
                  {review.reply}
                </span>
              </Box>
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
}
