import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import Rating from "@material-ui/lab/Rating";
import LanguageIcon from "@material-ui/icons/Language";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";
import PhoneAndroidOutlinedIcon from "@material-ui/icons/PhoneAndroidOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import {
  CompanyCardContainer,
  CompanyCategory,
  CompanyLink,
  CompanyAddress,
  Ratings,
  RatingText,
  CompanyDescription,
  CompanyPhone,
  CompanyCardFlex,
} from "./styles";
import styled from "styled-components";
import useMedia from "./useMedia";

export default function CompanyCard({ company }) {
  const history = useHistory();
  const small = useMedia("(max-width: 700px)");

  // const subStringLength = small ? 200 : 400;
  const subStringLength = 200;
  return (
    <CompanyCardContainer
      onClick={() => {
        if (small) {
          history.push("/" + company.id);
        }
      }}
    >
      <CardContent
        style={{ flexGrow: 1, padding: "18px 32px", paddingBottom: 0 }}
      >
        <CompanyCategory>{company.category}</CompanyCategory>
        <CompanyCardFlex>
          <div style={{ flexGrow: 1 }}>
            <CompanyLink to={"/" + company.id}>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", marginTop: small ? 24 : 0 }}
              >
                {company.name}
              </Typography>
            </CompanyLink>
            <CompanyAddress>
              <LocationOnOutlinedIcon fontSize="small" /> {company.address}
            </CompanyAddress>
            <Ratings>
              <Rating
                name="hover-feedback"
                value={company.averageStars || 0}
                precision={0.5}
                readOnly
                size="small"
              />
              <RatingText count={company.ratingsCount}>
                {company.ratingsCount
                  ? `${company.ratingsCount} reviews`
                  : "No reviews yet"}
              </RatingText>
            </Ratings>
            <CompanyDescription>
              {company.description.length > subStringLength ? (
                <>
                  {company.description.substring(0, subStringLength)}
                  {"  "}
                  <CompanyLink to={"/" + company.id}>...read more</CompanyLink>
                </>
              ) : (
                company.description
              )}
            </CompanyDescription>
          </div>
          <div>
            <img
              src={company.imageURL}
              style={{ width: 200, borderRadius: 8 }}
              alt=""
            />
            {/* {company.phone ? (
              <CompanyPhone href={`tel:${company.phone}`}>
                <PhoneAndroidOutlinedIcon /> {company.phone}
              </CompanyPhone>
            ) : null} */}
            {company.phone ? (
              <a
                style={{ textDecoration: "none" }}
                href={`tel:${company.phone}`}
                target="__blank"
              >
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  style={{
                    width: 150,
                    margin: 4,
                    borderRadius: 4,
                    justifyContent: "flex-start",
                  }}
                  startIcon={
                    <PhoneAndroidOutlinedIcon
                      style={{ height: 24, width: 24 }}
                    />
                  }
                >
                  {company.phone}
                </Button>
              </a>
            ) : null}
          </div>
        </CompanyCardFlex>
      </CardContent>
      <CardActions style={{ padding: "8px 32px" }}>
        {/* <Button size="small" color="primary" startIcon={<LanguageIcon />}>
          Website
        </Button>
        <Button
          size="small"
          color="primary"
          startIcon={<MailOutlineOutlinedIcon />}
        >
          Mail
        </Button>
        <Button size="small" color="primary" startIcon={<MapOutlinedIcon />}>
          Map
        </Button> */}
      </CardActions>
    </CompanyCardContainer>
  );
}
