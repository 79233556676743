import React from "react";
import red from "@material-ui/core/colors/red";
import SVG from "react-inlinesvg";
import IcoMoon from "react-icomoon";
const iconSet = require("./icons/selection.json");

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(require.context("./icons", false, /\.(svg)$/));

const defaultStyles = { fill: red["500"], height: 40, width: 40 };

export default function Icon({ category, styles }) {
  if (category.icon_pack === "mdi")
    return (
      <SVG
        style={{ ...defaultStyles, ...styles }}
        src={images[category.icon_name + ".svg"]}
      />
    );
  return (
    <IcoMoon
      iconSet={iconSet}
      name={category.icon_name}
      style={{ ...defaultStyles, ...styles }}
    />
  );
}
