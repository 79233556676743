import React, { useEffect } from "react";

import moment from "moment";
import { useForm } from "react-hook-form";
import {
  Container,
  CardContent,
  TextField,
  Button,
  Checkbox,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import { CompanyCategory, CompanyCardContainer } from "./styles";

import { DevTool } from "react-hook-form-devtools";
import { useState } from "react";
import { getCategories, sendApplication } from "../firebase";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import Loading from "./Loading";
import useMedia from "./useMedia";
import firebase from "firebase";

let days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export default function NewCompany() {
  const [categories, setCategories] = useState();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [checkedTOC, setCheckedTOC] = useState(true);
  const history = useHistory();

  const small = useMedia("(max-width: 700px)");

  useEffect(() => {
    getCategories().then(setCategories);
  }, []);

  const { register, handleSubmit, errors, control, watch } = useForm({
    defaultValues: {
      phone: "+61",
      website: "https://",
      states: [],
      category: "",
      trading_hours: days.map((day) => ({
        day,
        opening_time: moment().set({ hour: 10, minute: 0 }),
        closing_time: moment().set({ hour: 18, minute: 0 }),
      })),
    },
  });

  function onSubmit(values) {
    if (!checkedTOC) {
      alert("You need to agree to the Terms and Conditions to Continue");
      return;
    }
    setLoading(true);
    values.createdAt = Date.now();

    sendApplication(values)
      .then((res) => {
        setLoading(false);
        enqueueSnackbar("Thank You! Your Application has been submitted!", {
          variant: "success",
        });
        history.push("/");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        enqueueSnackbar("Oops! Unexpected error! Please try again later", {
          variant: "error",
        });
      });
  }

  if (!categories || loading) return <Loading />;

  return (
    <Container style={{ marginTop: 24, maxWidth: 800 }}>
      <DevTool control={control} />
      <CompanyCardContainer>
        <CardContent style={{ flexGrow: 1, padding: "18px 32px" }}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "100%",
              padding: small ? 0 : "0 32px",
            }}
          >
            <Typography style={{ margin: "20px 0" }}>
              Welcome To Kaamkotha Directory Listing! Please Fill the form below
              to apply your business to be listed in this site.
            </Typography>

            <CompanyCategory
              style={{ marginTop: 40, textDecoration: "underline" }}
            >
              Business Details
            </CompanyCategory>

            <TextField
              label="Business Name"
              name="name"
              fullWidth
              id="name"
              inputRef={register({ required: true })}
              helperText={!!errors.name && "Please enter your business name"}
              error={!!errors.name}
            />

            <CompanyCategory
              style={{ textDecoration: "underline", marginTop: 12 }}
            >
              Contact Details
            </CompanyCategory>

            <TextField
              label="Name"
              name="user_name"
              fullWidth
              id="user_name"
              inputRef={register({ required: true })}
              helperText={!!errors.user_name && "Please enter your name"}
              error={!!errors.user_name}
            />
            <TextField
              label="Email"
              name="user_email"
              style={{ marginTop: "1.5em" }}
              fullWidth
              type="email"
              id="user_email"
              inputRef={register({ required: true })}
              helperText={!!errors.user_email && "Please enter your email"}
              error={!!errors.user_email}
            />
            <TextField
              label="Phone No"
              name="user_phone"
              fullWidth
              style={{ marginTop: "1.5em" }}
              id="user_phone"
              inputRef={register({ required: true })}
              helperText={
                !!errors.user_phone && "Please enter your contact number"
              }
              error={!!errors.user_phone}
            />
            <FormControlLabel
              label={
                <span style={{ fontSize: "0.9em" }}>
                  I agree to the{" "}
                  <a href="./toc.html" target="_blank" style={{ color: "red" }}>
                    Terms and Conditions
                  </a>{" "}
                  for Nepalese Business Directory
                </span>
              }
              control={
                <Checkbox
                  color="primary"
                  checked={checkedTOC}
                  onChange={(v) => setCheckedTOC(v.target.checked)}
                />
              }
            />

            <br />
            <br />
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </form>
        </CardContent>
      </CompanyCardContainer>
    </Container>
  );
}
